import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {IonGrid, IonCol, IonRow, IonButton, IonIcon, IonRange} from '@ionic/react';
import {bulbOutline,carSportOutline,refresh,walkOutline,timeOutline,resize,waterOutline,thermometer,power,sunnyOutline,playOutline} from 'ionicons/icons';
import './Buttons.css';

let lampurl="";

let c=-1;
let b=-1;
let m=-1;
let v=-1;
//let s=-1;
//let t=-1;

let oldc=-1;
let oldb=-1;
let oldm=-1;
let oldv=-1;
//let olds=-1;
//let oldt=-1;


let connecting="ion-text-center visible";
let bclassbase1 ="ion-no-margin ion-color ion-color-mcolor_";
let bclassbase2 =" md button button-full button-solid ion-activatable ion-focusable hydrated ";
let bclasswhite="ion-no-margin ion-color ion-color-white md button button-full button-solid ion-activatable ion-focusable hydrated ";
let bclass:string[]=[];
let bmclass:string[]=[];

let activecolor=-1;
let activemode=-1;
let colors_placeholder_class="hidden";
let brightness_placeholder_class="hidden";
let speed_placeholder_class="hidden";

for (let i = 0; i < 20; i++) {
    bclass.push(bclassbase1+String.fromCharCode(97+i)+bclassbase2+"unselected");
}
// we don't need 20 - it's a dirty trick to avoid a map
for (let i = 0; i < 20; i++) {
    bmclass.push(bclasswhite+"unselected");
}
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
function goServer () {
    let url=lampurl;
    let result:any
    const fetchData = async () => {
	let params="";

	try {
	    if(c!=oldc){
		bclass[activecolor]=bclassbase1+String.fromCharCode(97+activecolor)+bclassbase2+"unselected";
		activecolor=c-1;
		bclass[activecolor]=bclassbase1+String.fromCharCode(97+activecolor)+bclassbase2+"selected";
		oldc=c;
		params+="&c="+c;
	    }
	    if(m!=oldm){
                bmclass[activemode]=bclasswhite+"unselected";
                bmclass[m]=bclasswhite+"selected";
                activemode=m;
		if(m===2 || m===3 || m===4 || m===6 || m===10){
		    colors_placeholder_class="hidden";
		} else {
		    colors_placeholder_class="visible";
		}
		if(m==6 || m==5 || m==11){
		    brightness_placeholder_class="hidden";
		} else {
		    brightness_placeholder_class="visible";
		}
		if(m==1 || m==2 || m==3 || m==12){
		    speed_placeholder_class="visible";
		} else {
		    speed_placeholder_class="hidden";
		}
		oldm=m;
		params+="&m="+m;
	    }
	    if(b!=oldb){
		oldb=b;
		params+="&b="+b;
	    }
	    if(v!=oldv){
		oldv=v;
		params+="&v="+v;
	    }
	    
	    result = await axios(url+params);
	} catch (error) {
	}
    };
    fetchData();
    return result;
}

function useLight(light:string) {
    const [data,setData]=useState({c:-1,v:-1,b:-1,m:-1});
    const forceUpdate = useForceUpdate();
    lampurl=light;
    useEffect(() => {
	const fetchData = async () => {
	    //connecting="ion-text-center visible";
	    const result = await axios(
		lampurl,
	    );
	    connecting="ion-text-center hidden";
	    setData(result.data);
            activecolor=result.data.c-1;
	    oldc=result.data.c;
	    m=result.data.m;
	    activemode=m;
	    oldm=m;
	    b=result.data.b;
	    oldb=b;
	    v=result.data.v;
	    oldv=v;
	    bclass[activecolor]=bclassbase1+String.fromCharCode(97+activecolor)+bclassbase2+"selected";
	    bmclass[m]=bclasswhite+"selected";
		if(m==2 || m==3 || m==4 || m==6 || m==10){
		    colors_placeholder_class="hidden";
		} else {
		    colors_placeholder_class="visible";
		}
		if(m==6 || m==5 ||m==11){
		    brightness_placeholder_class="hidden";
		} else {
		    brightness_placeholder_class="visible";
		}
		if(m==1 || m==2 || m==3 || m==12){
		    speed_placeholder_class="visible";
		} else {
		    speed_placeholder_class="hidden";
		}
	    forceUpdate();
	    
	};

	fetchData();
    }, []);
    
    return (
	<div>
	    <div>
		<p></p>
      		<div className={colors_placeholder_class}>
		    <IonGrid>
			<IonRow>
			    <IonCol>
			    <IonButton expand="full" class={bclass[0]} color="mcolor_a" onClick={ () => {c=1;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[1]} color="mcolor_b" onClick={ () => {c=2;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[2]} color="mcolor_c" onClick={ () => {c=3;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			<IonButton expand="full" class={bclass[3]} color="mcolor_d" onClick={ () => {c=4;goServer();forceUpdate()}}>
			</IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bclass[4]} color="mcolor_e" onClick={ () => {c=5;goServer();forceUpdate()}}>
			</IonButton></IonCol>
			</IonRow>
			<IonRow>
			    <IonCol>
			    <IonButton expand="full" class={bclass[5]} color="mcolor_f" onClick={ () => {c=6;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[6]} color="mcolor_g" onClick={ () => {c=7;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[7]} color="mcolor_h" onClick={ () => {c=8;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			<IonButton expand="full" class={bclass[8]} color="mcolor_i" onClick={ () => {c=9;goServer();forceUpdate()}}>
			</IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bclass[9]} color="mcolor_j" onClick={ () => {c=10;goServer();forceUpdate()}}>
			</IonButton></IonCol>
			</IonRow>
			<IonRow>
			    <IonCol>
			    <IonButton expand="full" class={bclass[10]} color="mcolor_k" onClick={ () => {c=11;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[11]} color="mcolor_l" onClick={ () => {c=12;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[12]} color="mcolor_m" onClick={ () => {c=13;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[13]} color="mcolor_n" onClick={ () => {c=14;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			<IonButton expand="full" class={bclass[14]} color="mcolor_o" onClick={ () => {c=15;goServer();forceUpdate()}}>
			</IonButton></IonCol>
			</IonRow>
			<IonRow>
			    <IonCol>
			    <IonButton expand="full" class={bclass[15]} color="mcolor_p" onClick={ () => {c=16;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[16]} color="mcolor_q" onClick={ () => {c=17;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[17]} color="mcolor_r" onClick={ () => {c=18;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[18]} color="mcolor_s" onClick={ () => {c=19;goServer();forceUpdate()}}>
			    </IonButton></IonCol>
			    <IonCol>
			    <IonButton expand="full" class={bclass[19]} color="mcolor_t" onClick={ () => {c=20;goServer();forceUpdate()}}>
		    </IonButton></IonCol>
		    </IonRow>
		    </IonGrid>
		    </div>
	    <IonRow class="divider50"></IonRow>
		    <IonGrid>
			<IonRow>
			<IonCol>
			<IonButton expand="full" class={bmclass[0]} color="white" onClick={() => {m=0;goServer(); forceUpdate()}}>
			<IonIcon slot="icon-only" icon={bulbOutline} /></IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bmclass[1]} color="white" onClick={() => {m=1;goServer(); forceUpdate()}}>
			<IonIcon slot="icon-only" icon={carSportOutline} /></IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bmclass[2]} color="white" onClick={() => {m=2;goServer(); forceUpdate()}}>
			<IonIcon slot="icon-only" icon={refresh} />1</IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bmclass[3]} color="white" onClick={() => {m=3;goServer(); forceUpdate()}}>
			<IonIcon slot="icon-only" icon={refresh} />2</IonButton></IonCol>
			<IonCol>
			<IonButton expand="full" class={bmclass[5]} color="white" onClick={() => {m=5;goServer(); forceUpdate()}}>
			<IonIcon slot="icon-only" icon={walkOutline} /></IonButton></IonCol>
			</IonRow>
			<IonRow>
			    <IonCol>
			<IonButton expand="full" class={bmclass[4]} color="white" onClick={() => {m=4;goServer(); forceUpdate()}}>
	<IonIcon slot="icon-only" icon={timeOutline} /></IonButton></IonCol>
	<IonCol>
	<IonButton expand="full" class={bmclass[11]} color="white" onClick={() => {m=11;goServer(); forceUpdate()}}>
	<IonIcon slot="icon-only" icon={resize} /></IonButton></IonCol>
	<IonCol>
	<IonButton expand="full" class={bmclass[12]} color="white" onClick={() => {m=12;goServer(); forceUpdate()}}>
	<IonIcon slot="icon-only" icon={waterOutline} /></IonButton></IonCol>
	<IonCol>
	<IonButton expand="full" class={bmclass[10]} color="white" onClick={() => {m=10;goServer(); forceUpdate()}}>
	<IonIcon slot="icon-only" icon={thermometer} /></IonButton></IonCol>
	<IonCol>
	<IonButton expand="full" class={bmclass[6]} color="white" onClick={() => {m=6;goServer(); forceUpdate()}}>
	<IonIcon slot="icon-only" icon={power} /></IonButton></IonCol>
	</IonRow>
				</IonGrid>
	    <IonRow class="divider50"></IonRow>
	    <div className={brightness_placeholder_class}>
	    <IonRange snaps={true} debounce={20} ticks={false} mode="ios" color="medium" value={b} min={1} max={10} step={1} onIonChange={e=>{b=e.detail.value as number;goServer()}}>
	    <IonIcon size="small" slot="start" icon={sunnyOutline} />
            <IonIcon size="large" slot="end" icon={sunnyOutline} />
			    </IonRange>
	    </div>
	    	    <IonRow class="divider20"></IonRow>

	    <div className={speed_placeholder_class}>
	    <IonRange snaps={true} debounce={20} ticks={false} mode="ios" color="medium" value={v} min={1} max={10} step={1} onIonChange={e=>{v=e.detail.value as number;goServer()}}>
		<IonIcon size="small" slot="start" icon={playOutline} />
	    <IonIcon size="large" slot="end" icon={playOutline} />
	</IonRange>
</div>	
	
	
	</div>
	    <div className={connecting}>
        <h3>Connecting...</h3>
      </div>
	</div>
    );
}

export default useLight;

