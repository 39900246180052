import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import UseLightVidja from '../components/UseLightVidja';
import './Tab3.css';

const Tab3: React.FC = () => {
  return (
      <IonPage>
	  <IonHeader>
              <IonToolbar>
		  <IonTitle class="ion-text-center" size="large">Light Controller</IonTitle>
              </IonToolbar>
	  </IonHeader>
	  <IonContent fullscreen>
              <IonHeader collapse="condense">
		  <IonToolbar>
		      <IonTitle size="large" class="ion-text-center">MICHELE - DOV'E' QUESTO TESTO??</IonTitle>
		  </IonToolbar>
              </IonHeader>
          {UseLightVidja("http://192.168.0.52/?")}
	  </IonContent>
      </IonPage>
  );
};

export default Tab3;
